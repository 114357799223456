import {Route, Routes} from 'react-router-dom'
import Index from '../views'
import NewApplication from '../views/new-application'
import Profile from '../views/profile'
import Documents from '../views/documents'

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/documents/:applicationId" element={<Documents />} />
      <Route
        path="/edit-application/:applicationId"
        element={<NewApplication />}
      />
    </Routes>
  )
}

export default AppRoutes
