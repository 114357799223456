import {Box, Text} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {Link} from 'react-router-dom'

interface BackLinkProps {
  text: string
  url: string
}

const BackLink: React.FC<BackLinkProps> = ({url, text}) => {
  return (
    <Box textTransform="uppercase" color="#03203E">
      <Text fontSize="16px" fontWeight="bold">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        &nbsp;
        <Link to={url}>{text}</Link>
      </Text>
    </Box>
  )
}

export default BackLink
