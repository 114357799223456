import {Box, ChakraProvider} from '@chakra-ui/react'
import theme from './theme/theme'
import AppRoutes from './routes'
import GlobalStyles from './theme/GlobalStyles'
import Fonts from './theme/Fonts'
import Header from './components/Header/Header'
import {library} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/pro-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fal} from '@fortawesome/pro-light-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fad} from '@fortawesome/pro-duotone-svg-icons'
import Footer from './components/Footer/Footer'
import {ApolloProvider} from '@apollo/client'
import client from './services/apollo-client'

export const App = () => {
  library.add(far, fab, fal, fas, fad)

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme} resetCSS>
        <Fonts />
        <GlobalStyles />
        <Header />
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          sx={{'> div': {flexGrow: 1}}}
        >
          <AppRoutes />
        </Box>
        <Footer />
      </ChakraProvider>
    </ApolloProvider>
  )
}
