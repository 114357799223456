import {Spinner, Table, Tbody, Td, Th, Thead, Tr, Text} from '@chakra-ui/react'
import React from 'react'
import {Link} from 'react-router-dom'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import {ApplicationStatus} from '../../interfaces/generated/globalTypes'
import {ApolloError} from '@apollo/client'
import {ApplicationFields} from '../../interfaces/generated/ApplicationFields'
import withPageContainer, {WithPageContainerProps} from '../../hoc/withPageContainer'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import {STATUS_TEXT} from '../../constants/applications'
import {Applications} from '../../interfaces/generated/Applications'

export interface DashboardTableProps extends WithPageContainerProps {
  data: Applications
  loading: boolean
  error: ApolloError|undefined
}

const headers = ['Applicant', 'Date Started', 'Designation Started', 'Status']
const DashboardTable: React.FC<Partial<DashboardTableProps>> = ({data, loading, error, user} ) => {
  return (
    <Table variant="simple">
      <Thead bg="primary" textTransform="uppercase">
        <Tr>
          {headers.map((header, i) => (
            <Th color="#FFF" fontSize="16px" key={i}>
              {header}
            </Th>
          ))}
          <Th />
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {loading ? (
          <Spinner />
        ) : error ? (
          <>
            { user ? (
              <ErrorMessage>Error fetching applications</ErrorMessage>
            ) : (
              <Text textAlign="center">User Not Signed In</Text>
            )}
          </>
        ) : (
          data?.applications?.data.map((cnt: ApplicationFields, i: number) => (
          <Tr key={i}>
            <Td>
              <b>{cnt.name ?? '-'}</b>
            </Td>
            <Td>{cnt.initialNoticeStartDate ? (new Date(cnt.initialNoticeStartDate)).toLocaleDateString() : '-'}</Td>
            <Td>{cnt.designationStartDate ? (new Date(cnt.designationStartDate)).toLocaleDateString() : '-'}</Td>
            <Td>{STATUS_TEXT[cnt.status]}</Td>
            <Td>
              <Link to={`/edit-application/${cnt.id}`}>
                <PrimaryButton disabled={cnt.status === ApplicationStatus.COMPLETE} key={i}>{
                  cnt.status === ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS ?
                    'CONTINUE INITIAL' : cnt.status === ApplicationStatus.INITIAL_NOTICE_COMPLETE ?
                    'START DESIGNATION' : cnt.status === ApplicationStatus.DESIGNATION_IN_PROGRESS ?
                      'CONT DESIGNATION' : 'COMPLETE'
                }</PrimaryButton>
              </Link>
            </Td>
            { (cnt.status !== ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS)&& (
              <Td>
                <Link to={`documents/${cnt.id}`}>
                  <SecondaryButton key={i}>Documents</SecondaryButton>
                </Link>
              </Td>
            )}
          </Tr>
        )))}
      </Tbody>
    </Table>
  )
}
export default withPageContainer(DashboardTable)
