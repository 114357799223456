import {Box, Flex, Heading, Spinner, Text, useMediaQuery} from '@chakra-ui/react'
import React from 'react'
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import DocumentTable from '../../components/DocumentTable/DocumentTable'
import MobileDocumentsTable from '../../components/MobileDocumentsTable/MobileDocumentsTable'
import {useParams} from 'react-router-dom'
import BackLink from '../../components/BackLink/BackLink'
import {useQuery} from '@apollo/client'
import {APPLICATION} from '../../services/aceFmlaGraphQL'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'

const Document: React.FC = () => {
  const params = useParams();
  const { applicationId } = params;
  const { data, loading, error } = useQuery(APPLICATION, {
    variables: { id: applicationId }
  });
  const [isDesktop] = useMediaQuery('(min-width:1024px)')
  return (
    <ContentWrapper
      flexDirection="column"
      py="50px"
      justifyContent="space-evenly"
      height="100%"
    >
      { loading ? <Spinner /> : error ? (
        <ErrorMessage>Error Fetching Application Data with id: {applicationId}</ErrorMessage>
      ) : (
        <>
          <Flex justifyContent="space-between">
            <Heading textTransform="uppercase">documents</Heading>

            <Box display={{base: 'none', lg: 'flex'}}>
              <BackLink url="/" text="back to dashboard" />
            </Box>
          </Flex>
          <Text fontSize="16px" py="45px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu
            felis nibh. Vestibulum ante ipsum primis in faucibus orci luctus et
            ultrices posuere cubilia curae. Ut tristique accumsan enim.{' '}
          </Text>
          {isDesktop ? <DocumentTable application={data.application} /> : <MobileDocumentsTable application={data.application} />}
        </>
      )}

    </ContentWrapper>
  )
}

export default Document
