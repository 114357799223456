import { useFormik } from 'formik'
import {COMPANY, UPDATE_COMPANY} from '../services/aceFmlaGraphQL'
import {useMutation} from '@apollo/client'
import { useNavigate } from "react-router-dom";

type UseUpdateCompanyFormProps = {
  phone: string
  addressLine1: string
  addressLine2: string
}

const useUpdateCompanyForm = ({ phone, addressLine1, addressLine2 }: UseUpdateCompanyFormProps) => {
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      addressLine1,
      addressLine2,
      phone,
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (input, { setErrors }) => {
      await updateCompany({
        variables: {
          input,
        },
        update: (store, { data: { updateCompany: company } }) => {
          store.writeQuery({
            query: COMPANY,
            data: {
              company
            }
          })
        }
      })
        .then(() => {
          alert('Company Updated')
        })
        .catch((error: any) => {
          if (error) {
            const { graphQLErrors } = error

            if (
              graphQLErrors[0] &&
              graphQLErrors[0].extensions.category === 'authentication'
            ) {
              if (
                graphQLErrors[0].message.match('You are already logged in.')
              ) {
                navigate('/')
                return
              }
              alert('Something went wrong')
              return
            }
          }
        })
    }
  })

  return { form: formik }
}
export default useUpdateCompanyForm
