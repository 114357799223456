import { Box, Flex, VStack, Text, Stack, StackDivider, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CustomInput from '../CustomInput/CustomInput'
import CustomSelect from '../CustomSelect/CustomSelect'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import useUpdateApplicationForm from '../../hooks/useUpdateApplicationForm'
import { ApplicationFields } from '../../interfaces/generated/ApplicationFields'
import { useQuery } from '@apollo/client'
import { GET_FMLA_REASONS, GET_OPTIONS, GET_STATES } from '../../services/aceFmlaGraphQL'
import { ApplicationStatus, OptionType } from '../../interfaces/generated/globalTypes'
import { FmlaReasonFields } from '../../interfaces/generated/FmlaReasonFields'

import { KeyValueFields } from '../../interfaces/generated/KeyValueFields'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { getCurrentDate } from '../../utils/date'
import { EMPLOYEE_STATES } from '../../constants/employeeStates'
import { FmlaDependentFields } from '../../interfaces/generated/FmlaDependentFields'
import { StateFields } from '../../interfaces/generated/StateFields'
import { EligibilityQuestionFields } from '../../interfaces/generated/EligibilityQuestionFields'

export type FMLAApplicationTableProps = {
  application: ApplicationFields
}

type EligibleQuestionProps = {
  question: EligibilityQuestionFields
  selected: string
  onChange: (e: React.ChangeEvent<any>) => void;
}

const EligibleQuestion = (props: EligibleQuestionProps) => {
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
      <Text
        minW={{ base: 'auto', lg: '525px' }}
        maxW={{ base: 'auto', lg: '525px' }}
      >
        {props.question.question}
      </Text>

      <CustomSelect
        maxW="94px"
        onChange={props.onChange}
      >

        <option value="yes" selected={props.selected === 'yes'}>Yes</option>
        <option value="no" selected={props.selected === 'no'}>No</option>
      </CustomSelect>
    </Stack >
  )
}

const FMLAApplicationTable = (props: FMLAApplicationTableProps) => {
  const { application } = props
  const navigate = useNavigate();
  const { form: updateApplicationForm } = useUpdateApplicationForm({ application })
  const [fmlaEligible, setFmlaEligible] = useState(application.fmlaIneligibilityReason === null)
  const [selectedReason, setSelectedReason] = useState<FmlaReasonFields>()
  const [visibleReasons, setVisibleReasons] = useState<FmlaReasonFields[]>([])
  const { data: fmlaReasonData, loading: fmlaReasonLoading, error: fmlaReasonError } = useQuery(GET_FMLA_REASONS)
  const { data: statesData, loading: statesLoading, error: statesError } = useQuery(GET_STATES)
  const { data: ineligibilityReasonData, loading: ineligibilityReasonLoading, error: ineligibilityReasonError } = useQuery(GET_OPTIONS, {
    variables: {
      type: OptionType.INELIGIBILIY_REASONS
    }
  })

  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    if (fmlaReasonData) {
      const visible = fmlaReasonData?.getFmlaReasons?.filter((v: FmlaReasonFields, k: number) => {
        const workstate = updateApplicationForm.values.employeeWorkState
        if (EMPLOYEE_STATES.indexOf(v.state?.toLowerCase() || '') > -1) {
          return v.state?.toLowerCase() === workstate?.toLowerCase()
        }
        // return v.state === EMPLOYEE_FEDERAL
        return false
      })
      setVisibleReasons(visible)
      updateApplicationForm.setFieldValue('fmlaReason', application.fmlaReason !== null ? visible.find((vis: any) => vis.slug === application.fmlaReason).slug : visible[0]?.slug)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fmlaReasonData, updateApplicationForm.values.employeeWorkState])

  useEffect(() => {
    if (updateApplicationForm.values.fmlaReason) {
      const reason = visibleReasons.find((v: FmlaReasonFields) => {
        return v.slug === updateApplicationForm.values.fmlaReason
      })
      setSelectedReason(reason)
      const dependents = reason?.fmlaDependentClass?.fmlaDependents
      if (dependents) {
        updateApplicationForm.setFieldValue('fmlaReasonDependent', dependents[0]?.slug)
      }
    } else {
      setSelectedReason(visibleReasons[0])
      updateApplicationForm.setFieldValue('fmlaReason', visibleReasons[0]?.slug)
      const dependents = visibleReasons[0]?.fmlaDependentClass?.fmlaDependents
      if (dependents) {
        updateApplicationForm.setFieldValue('fmlaReasonDependent', dependents[0]?.slug)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleReasons, updateApplicationForm.values.fmlaReason])

  useEffect(() => {
    if (!selectedReason) {
      return
    }
    const questionAnswers = (selectedReason && selectedReason.eligibilityQuestions) ? selectedReason?.eligibilityQuestions?.map((question: EligibilityQuestionFields) => {
      const exists = updateApplicationForm.values.eligibleAnswers?.find((answer) => answer.eligibilityQuestion.id === question.id)
      if (exists) {
        return exists
      }
      return {
        eligibilityQuestion: question,
        checked: false
      }
    }) : []
    // @ts-ignore
    updateApplicationForm.setFieldValue('eligibleAnswers', questionAnswers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReason])
  const handleEligibleAnswerChange = (id: string, value: boolean) => {
    const updatedAnswers = updateApplicationForm.values.eligibleAnswers?.map((question) => {
      if (question.eligibilityQuestion.id === id) {
        return {
          ...question,
          checked: value
        }
      }
      return question
    })
    // @ts-ignore
    updateApplicationForm.setFieldValue('eligibleAnswers', updatedAnswers)
  }
  useEffect(() => {
    if (updateApplicationForm.values.employeeWorkState) {
      setSelectedState(updateApplicationForm.values.employeeWorkState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Flex direction="column">
      <Flex
        bg="primary"
        textTransform="uppercase"
        minH="51px"
        color="#FFF"
        fontSize="16px"
        align="center"
        px="25px"
        fontWeight="bold"
      >
        {application.createdAt === application.updatedAt ? 'NEW' : ''} INITIAL NOTICE OF ELIGIBILITY
      </Flex>
      {(!selectedState) ? (<div>
        <VStack
          spacing={5}
          align="flex-start"
          py="25px"
          divider={<StackDivider borderColor="#03203E" />}
        >
          <Text align={'center'}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</Text>
          {statesLoading ? (
            <Spinner />
          ) : (
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>Employee's Work State</Text>

              {statesError ? (
                <ErrorMessage>Failed To Fetch States Data</ErrorMessage>
              ) : (
                <CustomSelect
                  id="employeeWorkState"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.employeeWorkState || selectedState}
                  minW={{ xl: '700px' }}
                  maxW="355px"
                >
                  <option value={''}>please select</option>
                  {statesData.getStates.filter((e: any) => EMPLOYEE_STATES.indexOf(e.value?.toLowerCase()) > -1).map((v: StateFields, k: number) => {
                    return <option
                      key={k}
                      value={v.value}
                      selected={v.value === application.employeeWorkState}
                    >
                      {v.label || ''}
                    </option>
                  })}
                </CustomSelect>
              )}
            </Stack>
          )}
        </VStack>
        <Box height={0} borderBottom="1px solid #03203E" />
        <Stack
          spacing={5}
          py="25px"
          justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <PrimaryButton onClick={() => {
            setSelectedState(updateApplicationForm.values.employeeWorkState ?? '');
            updateApplicationForm.submitForm()
          }}>Next</PrimaryButton>
        </Stack>
      </div>) :
        (<form onSubmit={updateApplicationForm.handleSubmit}>
          <VStack
            spacing={5}
            align="flex-start"
            py="25px"
            divider={<StackDivider borderColor="#03203E" />}
          >
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Start Date / Today's Date
              </Text>
              <Stack direction="column">
                <CustomInput
                  id="initialNoticeStartDate"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.initialNoticeStartDate}
                  maxW="202px"
                  type="date"
                />
                {updateApplicationForm.errors.initialNoticeStartDate && updateApplicationForm.touched.initialNoticeStartDate ? (
                    <ErrorMessage>start date is required</ErrorMessage>
                  ) : null}
              </Stack>
            </Stack>
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>Employee Name</Text>
              <Stack direction='column'>
                <CustomInput
                  id="name"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.name || ''}
                  minW={{ xl: '401px' }}
                  maxW="351px"
                  type="text"
                  placeholder="Regonald Perring"
                />
                {updateApplicationForm.errors.name && updateApplicationForm.touched.name ? (
                  <ErrorMessage>employee name is required</ErrorMessage>
                ) : null}
              </Stack>
            </Stack>
           
            {statesLoading ? (
              <Spinner />
            ) : (
              <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                <Text minW={{ base: 'auto', lg: '525px' }}>Employee's Work State</Text>

                {statesError ? (
                  <ErrorMessage>Failed To Fetch States Data</ErrorMessage>
                ) : (
                  <CustomSelect
                    isDisabled={true}
                    id="employeeWorkState"
                    onChange={updateApplicationForm.handleChange}
                    value={updateApplicationForm.values.employeeWorkState || selectedState}
                    minW={{ xl: '700px' }}
                    maxW="355px"
                  >
                    {
                      statesData.getStates.map((v: StateFields, k: number) => {
                        return <option
                          key={k}
                          value={v.value}
                          selected={v.value === application.employeeWorkState}
                        >
                          {v.label || ''}
                        </option>
                      })
                    }
                  </CustomSelect>
                )}
              </Stack>
            )}
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Date you learned about the FMLA or LOA
              </Text>

              <Stack dir='column'>
                <CustomInput
                  id="initialNoticeFmlaLoaLearntDate"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.initialNoticeFmlaLoaLearntDate}
                  max={getCurrentDate()}
                  type="date"
                  maxW="202px"
                />
                {updateApplicationForm.errors.initialNoticeFmlaLoaLearntDate && updateApplicationForm.touched.initialNoticeFmlaLoaLearntDate ? (
                      <ErrorMessage>required</ErrorMessage>
                    ) : null}
              </Stack>
            </Stack>
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Date you Intend to mail out or give the NOE to EE
              </Text>

              <Stack dir='column'>
                <CustomInput
                  id="intendedMailOutDate"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.intendedMailOutDate}
                  maxW="202px"
                  type="date"
                />
                {updateApplicationForm.errors.intendedMailOutDate && updateApplicationForm.touched.intendedMailOutDate ? (
                  <ErrorMessage>required</ErrorMessage>
                ) : null}
              </Stack>
            </Stack>
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Beginning Date of the LOA
              </Text>

              <Stack dir='column'>
                <CustomInput
                  id="initialNoticeLoaBeginDate"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.initialNoticeLoaBeginDate}
                  maxW="202px"
                  type="date"
                />
                {updateApplicationForm.errors.initialNoticeLoaBeginDate && updateApplicationForm.touched.initialNoticeLoaBeginDate ? (
                  <ErrorMessage>required</ErrorMessage>
                ) : null}
              </Stack>
            </Stack>
            {(fmlaReasonLoading && statesLoading) ? (
              <Spinner />
            ) : (
              <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                <Text minW={{ base: 'auto', lg: '525px' }}>Reason for Leave</Text>

                <Stack dir='column'>
                  {fmlaReasonError ? (
                    <ErrorMessage>Failed To Fetch FMLA Reason Data</ErrorMessage>
                  ) : (
                    <CustomSelect
                      id="fmlaReason"
                      onChange={updateApplicationForm.handleChange}
                      value={updateApplicationForm.values.fmlaReason || ''}
                      minW={{ xl: '700px' }}
                      maxW="355px"
                    >
                      {
                        visibleReasons.map((v: FmlaReasonFields, k: number) => {
                          return <option
                            key={k}
                            value={v.slug}
                            selected={v.slug === application.fmlaReason}
                          >
                            {v.name || ''}
                          </option>
                        })
                      }
                    </CustomSelect>
                  )}
                  {updateApplicationForm.errors.fmlaReason && updateApplicationForm.touched.fmlaReason ? (
                    <ErrorMessage>required</ErrorMessage>
                  ) : null}
                </Stack>
              </Stack>
            )}
            {selectedReason?.fmlaDependentClass?.fmlaDependents?.length && selectedReason?.fmlaDependentClass?.fmlaDependents?.length > 0 ? (
              <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                <Text minW={{ base: 'auto', lg: '525px' }}>Select Dependent/Family Member</Text>
                <CustomSelect
                  id="fmlaReasonDependent"
                  onChange={updateApplicationForm.handleChange}
                  value={updateApplicationForm.values.fmlaReasonDependent || ''}
                  minW={{ xl: '700px' }}
                  maxW="355px"
                >
                  {
                    selectedReason?.fmlaDependentClass?.fmlaDependents?.map((v: FmlaDependentFields, k: number) => {
                      return <option
                        key={k}
                        value={v.slug}
                        selected={v.slug === application.fmlaReasonDependent}
                      >
                        {v.name || ''}
                      </option>
                    })
                  }
                </CustomSelect>
              </Stack>
            ) : (<></>)}
            {
              selectedReason?.eligibilityQuestions?.length && selectedReason?.eligibilityQuestions?.length > 0 ? (
                <>
                  <Text minW={{ base: 'auto', lg: '525px' }}>
                    Eligibility Questions
                  </Text>
                  {selectedReason?.eligibilityQuestions?.map((question: EligibilityQuestionFields) => (
                    <EligibleQuestion
                      question={question}
                      selected={updateApplicationForm.values.eligibleAnswers?.find(answer => answer.eligibilityQuestion.id === question.id)?.checked ? 'yes' : 'no'}
                      onChange={(e) => {
                        handleEligibleAnswerChange(question.id, e.target.value === 'yes')
                      }}
                    />
                  ))}
                </>
              )
                : (<></>)}
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Is the EE Eligible for Family Leave based on service/hours requirement?
              </Text>

              <CustomSelect onChange={(e) => {
                setFmlaEligible(e.target.value === 'yes')
                if (e.target.value === 'yes') {
                  updateApplicationForm.setFieldValue('fmlaIneligibilityReason', null)
                }
              }} maxW="94px">
                <option value="yes" selected={application.fmlaIneligibilityReason ? false : true}>Yes</option>
                <option value="no" selected={application.fmlaIneligibilityReason ? true : false}>No</option>
              </CustomSelect>
            </Stack>
            {/* if no */}
            {
              !fmlaEligible ? (ineligibilityReasonLoading ? (
                <Spinner />
              ) : (
                <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                  <Text minW={{ base: 'auto', lg: '525px' }}>Select Reason Why EE is not eligible</Text>
                  {ineligibilityReasonError ? (
                    <ErrorMessage>Failed To Fetch FMLA Ineligible Reason Data</ErrorMessage>
                  ) : (
                    <CustomSelect
                      id="fmlaIneligibilityReason"
                      onChange={updateApplicationForm.handleChange}
                      value={updateApplicationForm.values.fmlaIneligibilityReason || ''}
                      minW={{ xl: '700px' }}
                      maxW="355px"
                    >
                      {
                        ineligibilityReasonLoading ?
                          <Spinner /> :
                          ineligibilityReasonData.getOptions.map((v: KeyValueFields, k: number) => {
                            return <option
                              key={k}
                              value={v.value}
                              selected={v.value === application.fmlaIneligibilityReason}
                            >
                              {v.label || ''}
                            </option>
                          })
                      }
                    </CustomSelect>
                  )}
                </Stack>
              )) : (<></>)
            }

            {/* end of if no */}
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>Is the EE a 'Key Employee'?</Text>

              <CustomSelect
                id="isKeyEmployee"
                onChange={(e) => {
                  updateApplicationForm.setFieldValue('isKeyEmployee', e.target.value === 'yes')
                }}
                maxW="94px"
              >
                <option value="yes" selected={application.isKeyEmployee === true}>Yes</option>
                <option value="no" selected={!application.isKeyEmployee}>No</option>
              </CustomSelect>
            </Stack>
          </VStack>
          <Box height={0} borderBottom="1px solid #03203E" />
          <Stack
            spacing={5}
            py="25px"
            justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <PrimaryButton type="submit">Save progress</PrimaryButton>
            <PrimaryButton onClick={() => {
              updateApplicationForm.submitForm().then(() => {
                navigate('/')
              })
            }}>Save and exit</PrimaryButton>
            <SecondaryButton onClick={() => {
              updateApplicationForm.setFieldValue('status', ApplicationStatus.INITIAL_NOTICE_COMPLETE)
              updateApplicationForm.submitForm().then(() => {
                navigate('/')
              })
            }}>Save & complete</SecondaryButton>
          </Stack>
        </form>)
      }

    </Flex >
  )
}

export default FMLAApplicationTable
