import { Flex, Heading, Text, Box, VStack, HStack, Image, Input, Spinner } from '@chakra-ui/react'
import React, { useRef } from 'react'
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import CustomInput from '../../components/CustomInput/CustomInput'
import BackLink from '../../components/BackLink/BackLink'
import useUpdateCompanyForm from '../../hooks/useUpdateCompanyForm'
import withPageContainer, { WithPageContainerProps } from '../../hoc/withPageContainer'
import { getImageUrl } from '../../utils/image'
import { useMutation } from '@apollo/client'
import { COMPANY, UPLOAD_COMPANY_LOGO } from '../../services/aceFmlaGraphQL'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'

const DashBoard = (props: WithPageContainerProps) => {
  const { user } = props
  const { form: updateCompanyForm } = useUpdateCompanyForm({
    phone: user?.company?.phone || '',
    addressLine1: user?.company?.addressLine1 || '',
    addressLine2: user?.company?.addressLine2 || '',
  })
  const [uploadCompanyLogo, { loading, error }] = useMutation(UPLOAD_COMPANY_LOGO);
  const fileInput = useRef(null)
  return (
    <ContentWrapper
      flexDirection="column"
      py="50px"
      justifyContent="space-evenly"
      height="100%"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading textTransform="uppercase">fmla profile</Heading>
        <Box display={{ base: 'none', lg: 'flex' }}>
          <BackLink url="/" text="back to dashboard" />
        </Box>
      </Flex>
      <Text fontSize="16px" py="45px">
        Complete the information below that will be populated in all FMLA
        generated documents
      </Text>
      <Box>
        <Box
          textTransform="uppercase"
          bg="primary"
          color="#FFF"
          py="15px"
          fontSize="16px"
          fontWeight="bold"
          px="25px"
        >
          COMPLETE YOUR COMPANY PROFILE
        </Box>
        <form onSubmit={updateCompanyForm.handleSubmit}>
          <VStack
            spacing={2}
            align="flex-start"
            divider={
              <Box width="100%" height="0" borderBottom="1px solid #03203E" />
            }
          >
            <HStack
              py="20px"
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              w={{ base: '100%', xl: '49%' }}
            >
              <Text pt="10px" fontSize={'16px'} fontWeight={400}>
                Company Logo (to appear on documentation)
              </Text>
              <VStack alignItems={'flex-start'}>
                {loading ? (
                  <Spinner size="150px" />
                ) : (
                  <Image
                    borderRadius="full"
                    boxSize="150px"
                    src={user?.company?.logo ? getImageUrl(user.company.logo) : ''}
                    alt="Company Logo"
                  />
                )}

                {error && (
                  <ErrorMessage>Error Uploading Image</ErrorMessage>
                )}
                <Input id="file" hidden type="file" ref={fileInput} onChange={async (e) => {
                  await uploadCompanyLogo({
                    variables: {
                      // @ts-ignore
                      file: e.target.files[0]
                    },
                    update: (store, { data: { uploadCompanyLogo: company } }) => {
                      store.writeQuery({
                        query: COMPANY,
                        data: {
                          company: {
                            ...company,
                            logo: `${company.logo}?t=${(new Date()).getTime()}`
                          }
                        }
                      })
                    }
                  }).then((res) => {
                    alert('image updated')
                  }).catch(e => {
                    console.log('todo: better error logging: ', e)
                  })
                }} />
                <Text fontSize="11px" lineHeight="28px" fontWeight="400">
                  Accepted formats: png or jpg
                </Text>

                <PrimaryButton
                  textTransform="uppercase"
                  onClick={() => {
                    // @ts-ignored
                    fileInput?.current?.click()
                  }}
                >
                  Upload
                </PrimaryButton>
              </VStack>
            </HStack>
            <HStack
              alignItems={'flex-start'}
              py="20px"
              justifyContent={'space-between'}
              w={{ base: '100%', xl: '60%' }}
            >
              <Text fontSize={'16px'} lineHeight="54px" fontWeight={400}>
                Company Address
              </Text>
              <VStack>
                <CustomInput
                  id="addressLine1"
                  placeholder="Street Address"
                  minW={{ base: '100%', lg: '401px' }}
                  onChange={updateCompanyForm.handleChange}
                  value={updateCompanyForm.values.addressLine1}
                />
                <CustomInput
                  id="addressLine2"
                  placeholder="City, State, Zip"
                  minW={{ base: '100%', lg: '401px' }}
                  onChange={updateCompanyForm.handleChange}
                  value={updateCompanyForm.values.addressLine2}
                />
              </VStack>
            </HStack>
            <HStack
              py="20px"
              justifyContent={'space-between'}
              w={{ base: '100%', xl: '60%' }}
            >
              <Text fontSize={'16px'} lineHeight="54px" fontWeight={400}>
                Company Phone
              </Text>
              <VStack>
                <CustomInput
                  id="phone"
                  placeholder="(123) 456-7890"
                  minW={{ base: '100%', lg: '401px' }}
                  onChange={updateCompanyForm.handleChange}
                  value={updateCompanyForm.values.phone}
                />
              </VStack>
            </HStack>
            <HStack justifyContent={'flex-end'} w="100%">
              <PrimaryButton
                type="submit"
                textTransform="uppercase"
                disabled={updateCompanyForm.isSubmitting}
              >
                {updateCompanyForm.isSubmitting && 'saving...'}
                {!updateCompanyForm.isSubmitting && 'save'}
              </PrimaryButton>
            </HStack>
          </VStack>
        </form>
      </Box>
    </ContentWrapper>
  )
}

export default withPageContainer(DashBoard)
