import {Flex, Stack, Text} from '@chakra-ui/react'
import React from 'react'
import {Link} from 'react-router-dom'
import ContentWrapper from '../ContentWrapper/ContentWrapper'

const footerLinks = [
  {
    pathname: '/',
    text: 'Privacy Policy',
  },
  {
    pathname: '/',
    text: 'ALPR Privacy Policy',
  },
  {
    pathname: '/',
    text: 'California Privacy Policy',
  },
  {
    pathname: '/',
    text: 'Consumer Data Request',
  },
]

const Footer: React.FC = () => {
  return (
    <ContentWrapper
      justifyContent="space-between"
      minH={{base: '197px', xl: '104px'}}
      background="#03203E"
      color="#FFF"
      fontSize="14px"
      w="100%"
    >
      <Flex direction="column" justifyContent="space-evenly">
        <Text fontSize="14px" lineHeight="14px" fontWeight="normal">
          &copy; {new Date().getFullYear()} ACE HR Pro. All Rights Reserved.
        </Text>
        <Stack
          direction={{base: 'column', lg: 'row'}}
          spacing={{base: '10px', md: '20px'}}
        >
          {footerLinks.map((lnk, i) => (
            <Link to={lnk.pathname} key={i}>
              {lnk.text}
            </Link>
          ))}
        </Stack>
      </Flex>
    </ContentWrapper>
  )
}

export default Footer
