import {Box, Flex, VStack, Text, HStack, Spinner} from '@chakra-ui/react'
import React from 'react'
import {Link} from 'react-router-dom'
import {
  DashboardTableProps,
} from '../DashboardTable/DashboardTable'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import {ApplicationFields} from '../../interfaces/generated/ApplicationFields'
import {ApplicationStatus} from '../../interfaces/generated/globalTypes'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import {STATUS_TEXT} from '../../constants/applications'

const MobileDashboardTable: React.FC<Partial<DashboardTableProps>> = ({
  data, loading, error,
}) => {
  return (
    <Flex direction="column">
      <Box
        textTransform="uppercase"
        bg="primary"
        color="#FFF"
        py="15px"
        fontSize="16px"
        fontWeight="bold"
        px="25px"
      >
        applications
      </Box>
      <VStack
        spacing={5}
        align="flex-start"
        divider={
          <Box width="100%" height="0" borderBottom="1px solid #03203E" />
        }
      >
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorMessage>Error fetching applications</ErrorMessage>
        ) : data?.applications?.data.map((cnt: ApplicationFields, i: number) => (
          <VStack
            key={i}
            justifyContent="space-evenly"
            align="flex-start"
            direction="column"
            py="20px"
            fontSize="16px"
            lineHeight="11px"
          >
            <Text fontWeight="bold" pb="25px">
              {cnt.name || '-'}
            </Text>
            <VStack align="flex-start" pb="25px">
              <HStack>
                <Text
                  color="primary"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  date started:{' '}
                </Text>
                <Text>{cnt.initialNoticeStartDate ? (new Date(cnt.initialNoticeStartDate)).toLocaleDateString('us') : '-'}</Text>
              </HStack>
              <HStack>
                <Text
                  color="primary"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  designation started:{' '}
                </Text>
                <Text>
                  {cnt.designationStartDate ? (new Date(cnt.designationStartDate)).toLocaleDateString('us') : '-'}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color="primary"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  status:{' '}
                </Text>
                <Text>{STATUS_TEXT[cnt?.status]}</Text>
              </HStack>
            </VStack>
            {/* buttons */}
            <VStack align="flex-start" spacing={5}>
              <Link to={`/edit-application/${cnt.id}`}>
                <PrimaryButton disabled={cnt.status === ApplicationStatus.COMPLETE} key={i}>{
                  cnt.status === ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS ?
                    'CONTINUE INITIAL' : cnt.status === ApplicationStatus.INITIAL_NOTICE_COMPLETE ?
                      'START DESIGNATION' : cnt.status === ApplicationStatus.DESIGNATION_IN_PROGRESS ?
                        'CONT DESIGNATION' : 'COMPLETE'
                }</PrimaryButton>
              </Link>
              {
                cnt.status !== ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS && (
                  <Link to={`documents/${cnt.id}`}>
                    <SecondaryButton key={i}>Documents</SecondaryButton>
                  </Link>
                )
              }
            </VStack>
          </VStack>
        ))}
      </VStack>
    </Flex>
  )
}

export default MobileDashboardTable
