import { gql } from '@apollo/client'

export const KEY_VALUE_FIELDS = gql`
  fragment KeyValueFields on KeyValue {
    label
    value
  }
`

export const FMLA_DEPENDENTS_FIELDS = gql`
  fragment FMLADependentsFields on FMLADependents {
    label
    value
    class
  }
`

export const FMLA_DEPENDENT_FIELDS = gql`
  fragment FmlaDependentFields on FmlaDependent {
    name
    slug
    definition
  }
`

export const STATE_FIELDS = gql`
  fragment StateFields on KeyValue {
    label
    value
  }
`

export const FMLA_DEPENDENT_CLASS_FIELDS = gql`
  ${FMLA_DEPENDENT_FIELDS}
  fragment FmlaDependentClassFields on FmlaDependentClass {
    name
    value
    fmlaDependents {
      ...FmlaDependentFields
    }
  },
`
export const ELIGIBILITY_QUESTION_FIELDS = gql`
  fragment EligibilityQuestionFields on EligibilityQuestion {
    id
    state
    question
    createdAt
    updatedAt
  },
`

export const FMLA_REASON_FIELDS = gql`
  ${ELIGIBILITY_QUESTION_FIELDS}
  ${FMLA_DEPENDENT_CLASS_FIELDS}
  fragment FmlaReasonFields on FmlaReason {
    name
    slug
    designationReason
    requiredDocument
    requiredDocumentInfo
    eligibilityQuestions {
      ...EligibilityQuestionFields
    }
    state
    fmlaDependentClass {
      ...FmlaDependentClassFields
    }
  }
`

export const COMPANY_FIELDS = gql`
  fragment CompanyFields on Company {
    id
    name
    logo
    phone
    addressLine1
    addressLine2
    externalCompanyId
    createdAt
    updatedAt
  }
`

export const APPLICATION_ELIGIBILITY_QUESTION_FIELDS = gql`
  ${ELIGIBILITY_QUESTION_FIELDS}
  fragment ApplicationEligibilityQuestionFields on ApplicationEligibilityQuestion {
    id
    eligibilityQuestion {
      ...EligibilityQuestionFields
    }
    checked
  }
`

export const PAGINATOR_INFO_FIELDS = gql`
  fragment PaginatorInfoFields on PaginatorInfo {
    count
    currentPage
    hasMorePages
    firstItem
    lastItem
    perPage
    total
  }
`

export const HUMAN_RESOURCE_FIELDS = gql`
  ${COMPANY_FIELDS}
  fragment HumanResourceFields on HumanResource {
    id
    name
    email
    externalHrId
    company {
      ...CompanyFields
    }
    createdAt
    updatedAt
  }
`

export const APPLICATION_FIELDS = gql`
  ${HUMAN_RESOURCE_FIELDS},
  ${COMPANY_FIELDS},
  ${APPLICATION_ELIGIBILITY_QUESTION_FIELDS},
  fragment ApplicationFields on Application {
    id
      humanResource {
        ...HumanResourceFields
      }
      company {
        ...CompanyFields
      }
      eligibilityQuestionAnswers {
        ...ApplicationEligibilityQuestionFields
      }
      initialNoticeStartDate
      name
      initialNoticeFmlaLoaLearntDate
      intendedMailOutDate
      initialNoticeLoaBeginDate
      fmlaReason
      fmlaReasonDependent
      fmlaIneligibilityReason
      isKeyEmployee
      designationStartDate
      fmlaInitialReason
      certificationReceivedDate
      fmlaApproved
      fmlaRejectedReason
      certificationIncompleteReason
      certificationInsufficientReason
      certificationSecondOrThirdOpinion
      newDueDate
      leaveType
      eeReturnDate
      designationFmlaLoaLearntDate
      designationLoaBeginDate
      status
      employeeWorkState
      completedAt
      createdAt
      updatedAt 
  }
`

export const APPLICATION = gql`
  ${APPLICATION_FIELDS}
  query Application($id: ID) {
    application(id: $id) {
      ...ApplicationFields
    }
  }
`

export const APPLICATIONS = gql`
  ${APPLICATION_FIELDS},
  ${PAGINATOR_INFO_FIELDS},
  query Applications($orderBy: [OrderByClause!], $status: ApplicationStatus, $completed: Boolean, $first: Int, $page: Int) {
    applications(orderBy: $orderBy, status: $status, completed: $completed, first: $first, page: $page) {
      data {
        ...ApplicationFields
      }
      paginatorInfo {
        ...PaginatorInfoFields
      }
    }
  }
`

export const CREATE_APPLICATION = gql`
  ${APPLICATION_FIELDS}
  mutation CreateApplication {
    createApplication {
      ...ApplicationFields
    }
  }
`

export const UPDATE_APPLICATION = gql`
  ${APPLICATION_FIELDS}
  mutation UpdateApplication($input: UpdateApplicationInput) {
    updateApplication(input: $input) {
      ...ApplicationFields
    }
  }
`

export const UPDATE_APPLICATION_ELIGIBILITY_QUESTION = gql`
  ${APPLICATION_FIELDS}
  mutation UpdateApplicationEligibilityQuestion($input: UpdateApplicationEligibilityQuestionInput) {
    updateApplicationEligibilityQuestion(input: $input) {
      ...ApplicationFields
    }
  }
`

export const GET_OPTIONS = gql`
  query GetOptions($type: OptionType!) {
    getOptions(type: $type) {
      ...on KeyValue {
        ...KeyValueFields      
      }
      ...on FMLADependents {
        ...FMLADependentsFields
      }
    }
  },
  ${KEY_VALUE_FIELDS},
  ${FMLA_DEPENDENTS_FIELDS},
`

export const GET_STATES = gql`
  query GetStates {
    getStates {
      ...StateFields      
    }
  },
  ${STATE_FIELDS},
`

export const DELETE_APPLICATION = gql`
  ${APPLICATION_FIELDS}
  mutation DeleteApplication($id: ID!) {
    deleteApplication(id: $id) {
      ...ApplicationFields
    }
  }
`

export const COMPANY = gql`
  ${COMPANY_FIELDS}
  query Company {
    company {
      ...CompanyFields
    }
  }
`

export const UPDATE_COMPANY = gql`
  ${COMPANY_FIELDS}
  mutation UpdateCompany($input: UpdateCompanyInput) {
    updateCompany(input: $input) {
      ...CompanyFields
    }
  }
`

export const UPLOAD_COMPANY_LOGO = gql`
  mutation UpdateCompanyLogo($file: Upload) {
    uploadCompanyLogo(file: $file) {
      ...CompanyFields
    }
  },
  ${COMPANY_FIELDS},
`

export const ME = gql`
  query Me {
    me {
      ...HumanResourceFields 
    }
  },
  ${HUMAN_RESOURCE_FIELDS},
`
export const LOGOUT = gql`
  mutation Logout {
    logout {
      ...HumanResourceFields
    }
  },
  ${HUMAN_RESOURCE_FIELDS},
`


export const GET_FMLA_REASONS = gql`
  query GetFmlaReasons {
    getFmlaReasons {
      ...FmlaReasonFields
    }
  },
  ${FMLA_REASON_FIELDS},
`