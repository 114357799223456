import {Input, InputProps} from '@chakra-ui/react'
import React from 'react'

const CustomInput: React.FC<InputProps> = props => {
  return <Input borderRadius="5px" {...props} />
}

CustomInput.defaultProps = {
  background: '#FFF',
  fontSize: '16px',
  borderRadius: '5px',
  border: '1px solid #000000',
  borderColor: '#000000',
  width: '100%',
  minH: '37px',
}

export default CustomInput
