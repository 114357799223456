import React from 'react'
import {Global} from '@emotion/react'
import 'focus-visible/dist/focus-visible'
const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={`
      .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }   
    html,body {
          min-height: 100vh;
          
        }

        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .clearfix:after {
          content: "";
          display: table;
          clear: both;
        }
      `}
    />
  )
}

export default GlobalStyles
