import { Text, TextProps } from "@chakra-ui/react"

const ErrorMessage: React.FC<TextProps> = (props) => {
  return (
    <Text color="red" minW={{base: 'auto', lg: '525px'}}>
      { props.children }
    </Text>
  )
}

export default ErrorMessage