import {Select, SelectProps} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'

const CustomSelect: React.FC<SelectProps> = props => {
  return (
    <Select
      background="#FFF"
      maxW="202px"
      maxH="37px"
      borderRadius="5px"
      outline="1px solid #000"
      icon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
      {...props}
    >
      {props.children}
    </Select>
  )
}

export default CustomSelect
