import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {Link} from 'react-router-dom'
import withPageContainer, {
  WithPageContainerProps,
} from '../../hoc/withPageContainer'
import ContentWrapper from '../ContentWrapper/ContentWrapper'
import {HIDE} from '../../constants/auth'

const links = [
  {
    pathname: '/',
    text: 'Service 1',
    hide: HIDE.NEVER,
  },
  {
    pathname: '/',
    text: 'Service 2',
    hide: HIDE.NEVER,
  },
  {
    pathname: '/profile',
    text: 'fmla profile',
    hide: HIDE.NO_AUTH,
  },
  {
    pathname: `/`,

    text: 'Sign In',
    hide: HIDE.AUTH,
    isLogin: true,
  },
  {
    pathname: '/',
    text: 'my account',
    hide: HIDE.NO_AUTH,
  },
]

const Header = (props: WithPageContainerProps) => {
  const {user} = props
  const {isOpen, onOpen, onClose} = useDisclosure()
  const visibleLinks = links.filter(lnk => {
    const auth = user ? HIDE.AUTH : HIDE.NO_AUTH
    return lnk.hide === HIDE.NEVER || lnk.hide !== auth
  })
  const redirectToAuth = (isLogin?: boolean) => {
    if (isLogin) {
      window.location.href = `${process.env.REACT_APP_DOCUMENT_BASE_URL}/authorize`
    }
  }

  return (
    <ContentWrapper
      justifyContent="space-between"
      minH={{base: '77px', xl: '160px'}}
      position="relative"
      background="#03203E"
      py="50px"
    >
      <Link to="/">
        <Flex
          direction="column"
          justifyContent="center"
          height="100%"
          maxW={{base: '80px', md: '100px', lg: '158.45px'}}
        >
          <Image
            src="/images/ace-hr-logo.png"
            height="100%"
            width="auto"
            objectFit="contain"
          />
        </Flex>
      </Link>
      <HStack
        alignSelf="flex-end"
        display={{base: 'none', lg: 'flex'}}
        spacing={5}
        color="#FFF"
        textTransform="uppercase"
        fontFamily="'Norwester'"
        fontSize="20px"
        lineHeight="24px"
      >
        {visibleLinks.map((lnk, i) => {
          return (
            <Link
              to={lnk.pathname}
              key={i}
              onClick={() => redirectToAuth(lnk.isLogin)}
            >
              <Text>{lnk.text}</Text>
            </Link>
          )
        })}
      </HStack>
      <Flex
        direction="column"
        justifyContent="center"
        display={{base: 'flex', lg: 'none'}}
        cursor="pointer"
        onClick={onOpen}
      >
        <FontAwesomeIcon icon={['fas', 'bars']} color="#FFF" size="2x" />
      </Flex>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent background="#03203E" color="#FFF">
          <DrawerHeader>
            <DrawerCloseButton
              pt="22px"
              pr="45px"
              color="white"
              width="25px"
              height="40px"
              outline="none"
            />
          </DrawerHeader>

          <DrawerBody>
            <Stack
              fontFamily="'Norwester'"
              textTransform="uppercase"
              fontSize="32px"
              mx="auto"
              py="50px"
            >
              {visibleLinks.map((lnk, i) => (
                <Link
                  to={lnk.pathname}
                  key={i}
                  onClick={() => redirectToAuth(lnk.isLogin)}
                >
                  <Text>{lnk.text}</Text>
                </Link>
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ContentWrapper>
  )
}

export default withPageContainer(Header)
