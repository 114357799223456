import {Box, Flex, VStack, Text} from '@chakra-ui/react'
import React from 'react'
import {tableContent, DocumentTableProps} from '../DocumentTable/DocumentTable'
import PrimaryButton from '../PrimaryButton/PrimaryButton'

const MobileDocumentTable: React.FC<Partial<DocumentTableProps>> = ({
  application,
}) => {
  return (
    <Flex direction="column">
      <Box
        textTransform="uppercase"
        bg="primary"
        color="#FFF"
        py="15px"
        fontSize="16px"
        fontWeight="bold"
        px="25px"
      >
        document name
      </Box>
      <VStack
        spacing={2}
        align="flex-start"
        divider={
          <Box width="100%" height="0" borderBottom="1px solid #03203E" />
        }
      >
        {tableContent(application?.id || '').filter((cnt) => {
          return (application?.fmlaReason === 'birth_of_a_child' && cnt.doc_name === 'HC-EE & Family Member Only')
        }).map((cnt, i) => (
          <VStack
            key={i}
            justifyContent="space-evenly"
            align="flex-start"
            direction="column"
            py="20px"
            fontSize="16px"
            lineHeight="21px"
          >
            <Text fontWeight="bold" pb="15px" maxW="266px">
              {cnt.doc_name}
            </Text>
            <VStack align="flex-start" spacing={5}>
              {cnt?.buttons.map((btn, i) => (
                <a href={btn.url} target="_blank" rel="noreferrer">
                  <PrimaryButton key={i} textTransform="uppercase">
                    {btn.text}
                  </PrimaryButton>
                </a>
              ))}
            </VStack>
          </VStack>
        ))}
      </VStack>
    </Flex>
  )
}

export default MobileDocumentTable
