import {Box, BoxProps} from '@chakra-ui/react'
import React from 'react'

const ContentWrapper: React.FC<BoxProps> = props => {
  return <Box {...props} />
}

ContentWrapper.defaultProps = {
  px: {base: '24px', md: '60px', xl: '100px'},
  display: 'flex',
}

export default ContentWrapper
