import {Flex, Heading, Stack, Text, useMediaQuery} from '@chakra-ui/react'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import MobileDashboardTable from '../../components/MobileDashboardTable/MobileDashboardTable'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import withPageContainer, {WithPageContainerProps} from '../../hoc/withPageContainer'
import {useMutation, useQuery} from '@apollo/client'
import {APPLICATIONS, CREATE_APPLICATION} from '../../services/aceFmlaGraphQL'
import {ApplicationStatus, OrderByClause, SortOrder} from '../../interfaces/generated/globalTypes'
import {STATUS_TEXT} from '../../constants/applications'
import CustomPaginator from '../../components/CustomPaginator/CustomPaginator'

export const defaultListFilter = {
  status: ApplicationStatus.ALL,
  orderBy: [{
    column: 'created_at',
    order: SortOrder.DESC
  }],
  page: 1,
  first: 20,
}

const DashBoard = (props: WithPageContainerProps) => {
  const [isDesktop] = useMediaQuery('(min-width:1024px)')

  const [orderBy, setOrderBy] = useState(defaultListFilter.orderBy as Array<OrderByClause>)
  const [page, setPage] = useState(defaultListFilter.page)
  const [filterStatus, setFilterStatus] = useState(defaultListFilter.status)
  const navigate = useNavigate();
  const { user } = props
  const [createApplication, { loading }] = useMutation(CREATE_APPLICATION);
  const { data: applicationsData, error: applicationsError, loading: applicationsLoading } = useQuery(APPLICATIONS, {
    variables: {
      orderBy,
      status: filterStatus,
      page,
      first: defaultListFilter.first,
    }
  })

  return (
    <ContentWrapper
      flexDirection="column"
      py="50px"
      justifyContent="space-evenly"
      height="100%"
    >
      <Flex justifyContent="space-between">
        <Heading textTransform="uppercase">dashboard</Heading>
        { user && (
            <PrimaryButton
              loadingText="Creating new application..."
              isLoading={loading}
              onClick={async () => {
                const { data } = await createApplication({})
                navigate(`/edit-application/${data?.createApplication?.id}`)
            }} display={{base: 'none', lg: 'block'}}>
              <Text textTransform="uppercase">start new application</Text>
            </PrimaryButton>
        )}
      </Flex>
      <Stack
        mt="25px"
        direction={{base: 'column', lg: 'row'}}
        spacing={5}
        alignItems={{lg: 'center'}}
      >
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="16px"
          lineHeight="38px"
        >
          sort by
        </Text>
        <CustomSelect
          onChange={(e) => setOrderBy([{
            column: e.target.value,
            order: SortOrder.DESC,
          }])}
          defaultValue={orderBy[0].column}
        >
          <option value="created_at">Created Date</option>
          <option value="initial_notice_start_date">Initial Notice Date</option>
          <option value="designation_start_date">Designation Date</option>
        </CustomSelect>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="16px"
          lineHeight="38px"
        >
          filter by status
        </Text>
        <CustomSelect onChange={(e) => setFilterStatus(e.target.value as ApplicationStatus)}>
          <option value={ApplicationStatus.ALL}>{STATUS_TEXT.ALL}</option>
          <option value={ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS}>{STATUS_TEXT.INITIAL_NOTICE_IN_PROGRESS}</option>
          <option value={ApplicationStatus.INITIAL_NOTICE_COMPLETE}>{STATUS_TEXT.INITIAL_NOTICE_COMPLETE}</option>
          <option value={ApplicationStatus.DESIGNATION_IN_PROGRESS}>{STATUS_TEXT.DESIGNATION_IN_PROGRESS}</option>
          <option value={ApplicationStatus.COMPLETE}>{STATUS_TEXT.COMPLETE}</option>
        </CustomSelect>
      </Stack>
      <Text fontSize="16px" py="45px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu
        felis nibh. Vestibulum ante ipsum primis in faucibus orci luctus et
        ultrices posuere cubilia curae. Ut tristique accumsan enim.{' '}
      </Text>

      {
        isDesktop ?
          <DashboardTable data={applicationsData} loading={applicationsLoading} error={applicationsError} /> :
          <MobileDashboardTable data={applicationsData} loading={applicationsLoading} error={applicationsError} />
      }
      <CustomPaginator
        total={applicationsData?.applications.paginatorInfo.total}
        perPage={applicationsData?.applications.paginatorInfo.perPage}
        page={page}
        setPage={setPage}
      />
    </ContentWrapper>
  )
}

export default withPageContainer(DashBoard)
