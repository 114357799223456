const {from, ApolloClient, InMemoryCache} = require('@apollo/client')
const {RetryLink} = require('@apollo/client/link/retry')
const { createUploadLink } = require('apollo-upload-client');

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_BASE_URL || '',
  credentials: 'include'
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([retryLink, httpLink]),
})

module.exports = client
