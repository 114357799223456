import { Box, Flex, FormErrorMessage, Spinner, Stack, StackDivider, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomInput from '../CustomInput/CustomInput'
import CustomSelect from '../CustomSelect/CustomSelect'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import { FMLAApplicationTableProps } from '../FMLAApplicationTable/FMLAApplicationTable'
import { useNavigate } from 'react-router-dom'
import useUpdateApplicationForm from '../../hooks/useUpdateApplicationForm'
import { useQuery } from '@apollo/client'
import { GET_OPTIONS } from '../../services/aceFmlaGraphQL'
import { ApplicationStatus, LeaveType, OptionType } from '../../interfaces/generated/globalTypes'
import { KeyValueFields } from '../../interfaces/generated/KeyValueFields'
import { getCurrentDate, getWeeksToCountAgainstFMLA } from '../../utils/date'
import { FmlaReasonFields } from '../../interfaces/generated/FmlaReasonFields'

const DesignationTable = (props: FMLAApplicationTableProps) => {
  const { application } = props
  const navigate = useNavigate();

  const [certificationIncomplete, setCertificationIncomplete] = useState(application.certificationIncompleteReason !== null)
  const [certificationInsufficient, setCertificationInsufficient] = useState(application.certificationInsufficientReason !== null)
  const [certificationSecondOpinion, setCertificationSecondOpinion] = useState(application.certificationSecondOrThirdOpinion !== null)
  const { form: updateApplicationForm } = useUpdateApplicationForm({ application })
  const { data: fmlaReasonData, loading: fmlaReasonLoading, error: fmlaReasonError } = useQuery(GET_OPTIONS, {
    variables: {
      type: OptionType.FMLA_REASONS
    }
  })
  const { data: fmlaApprovalData, loading: fmlaApprovalLoading, error: fmlaApprovalError } = useQuery(GET_OPTIONS, {
    variables: {
      type: OptionType.FMLA_APPROVAL
    }
  })
  const { data: fmlaRejectionData, loading: fmlaRejectionLoading, error: fmlaRejectionError } = useQuery(GET_OPTIONS, {
    variables: {
      type: OptionType.FMLA_REJECTED_REASONS
    }
  })
  return (
    <Flex direction="column">
      <Flex
        bg="primary"
        textTransform="uppercase"
        minH="51px"
        color="#FFF"
        fontSize="16px"
        align="center"
        px="25px"
        fontWeight="bold"
      >
        FOR DESIGNATION PURPOSES ONLY - ONLY COMPLETE AFTER REVIEWING RELATED
        DOCUMENTS, e.g. Health Cert., Relationship Affidavit
      </Flex>
      <form onSubmit={updateApplicationForm.handleSubmit}>
        <VStack
          spacing={5}
          align="flex-start"
          py="25px"
          divider={<StackDivider borderColor="#03203E" />}
        >
          <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
            <Text minW={{ base: 'auto', lg: '525px' }}>
              Start Date / Today's Date
            </Text>

            <CustomInput
              id="designationStartDate"
              onChange={updateApplicationForm.handleChange}
              value={updateApplicationForm.values.designationStartDate}
              maxW="202px"
              type="date"
            />
          </Stack>
          <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
            <Text minW={{ base: 'auto', lg: '525px' }}>
              Date in which you received the certification or affidavid
            </Text>

            <CustomInput
              id="certificationReceivedDate"
              onChange={updateApplicationForm.handleChange}
              value={updateApplicationForm.values.certificationReceivedDate}
              maxW="202px"
              type="date"
            />
          </Stack>
          {fmlaReasonLoading ? (<Spinner />) : (
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                What was the Initial Reason for FMLA?
              </Text>
              {fmlaReasonError ? (
                <FormErrorMessage>Failed To Fetch FMLA Reason Data</FormErrorMessage>
              ) : (
                <CustomSelect
                  id="fmlaInitialReason"
                  value={updateApplicationForm.values.fmlaInitialReason || ''}
                  onChange={updateApplicationForm.handleChange}
                  minW={{ lg: '500px', xl: '800px' }}
                >
                  {
                    fmlaReasonData.getOptions.map((v: FmlaReasonFields, k: number) => {
                      return <option
                        key={k}
                        value={v.slug}
                        selected={v.slug === application.fmlaReason}
                      >
                        {v.designationReason || ''}
                      </option>
                    })
                  }
                </CustomSelect>
              )}
            </Stack>
          )}

          <VStack spacing={5} align="flex-start">
            {fmlaApprovalLoading ? (
              <Spinner />
            ) : (
              <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                <Text minW={{ base: 'auto', lg: '525px' }}>
                  Is the FMLA Approved?
                </Text>
                {fmlaApprovalError ? (
                  <Text color="red" minW={{ base: 'auto', lg: '525px' }}>Failed To Fetch FMLA Approval Options</Text>
                ) : (
                  <CustomSelect
                    id="fmlaApproved"
                    onChange={updateApplicationForm.handleChange}
                    minW={{ lg: '500px', xl: '800px' }}
                  >
                    {fmlaApprovalData.getOptions.map((v: KeyValueFields, k: number) => {
                      return <option
                        key={k}
                        value={v.value}
                        selected={v.value === application.fmlaApproved}
                      >
                        {v.label || ''}
                      </option>
                    })}
                  </CustomSelect>
                )}
              </Stack>
            )}

            {updateApplicationForm.values.fmlaApproved === 'additional_information_needed' && (
              <>
                <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                  <Text minW={{ base: 'auto', lg: '525px' }}>
                    Is the certification considered incomplete?
                  </Text>
                  <CustomSelect
                    maxW="94px"
                    onChange={(e) => {
                      setCertificationIncomplete(e.target.value === 'yes')
                    }}
                  >
                    <option
                      key={1}
                      value="yes"
                      selected={certificationIncomplete}
                    >
                      Yes
                    </option>
                    <option
                      key={2}
                      value="no"
                      selected={!certificationIncomplete}
                    >
                      No
                    </option>
                  </CustomSelect>
                </Stack>

                {!certificationIncomplete && (
                  <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                    <Text minW={{ base: 'auto', lg: '525px' }}>
                      Is the certification considered insufficient?
                    </Text>
                    <CustomSelect
                      maxW="94px"
                      onChange={(e) => {
                        setCertificationInsufficient(e.target.value === 'yes')
                      }}
                    >
                      <option
                        key={1}
                        value="yes"
                        selected={certificationInsufficient}
                      >
                        Yes
                      </option>
                      <option
                        key={2}
                        value="no"
                        selected={!certificationInsufficient}
                      >
                        No
                      </option>
                    </CustomSelect>
                  </Stack>
                )}
                {!certificationIncomplete && !certificationInsufficient && (
                  <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                    <Text minW={{ base: 'auto', lg: '525px' }}>
                      Is a 2nd or 3rd Opinion Required?
                    </Text>
                    <CustomSelect
                      maxW="94px"
                      onChange={(e) => {
                        setCertificationSecondOpinion(e.target.value === 'yes')
                      }}
                    >
                      <option
                        key={1}
                        value="yes"
                        selected={certificationSecondOpinion}
                      >
                        Yes
                      </option>
                      <option
                        key={2}
                        value="no"
                        selected={!certificationSecondOpinion}
                      >
                        No
                      </option>
                    </CustomSelect>
                  </Stack>
                )}
                {!certificationSecondOpinion && (certificationIncomplete || certificationInsufficient) && (
                  <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                    <Text minW={{ base: 'auto', lg: '525px' }}>
                      What info is needed to make the Cert complete or sufficient?
                    </Text>

                    {certificationIncomplete ? (
                      <CustomInput
                        id="certificationIncompleteReason"
                        onChange={updateApplicationForm.handleChange}
                        value={updateApplicationForm.values.certificationIncompleteReason || ''}
                        placeholder="Free Text Input"
                        minW={{ lg: '500px', xl: '800px' }}
                      />
                    ) : (
                      <CustomInput
                        id="certificationInsufficientReason"
                        onChange={updateApplicationForm.handleChange}
                        value={updateApplicationForm.values.certificationInsufficientReason || ''}
                        placeholder="Free Text Input"
                        minW={{ lg: '500px', xl: '800px' }}
                      />
                    )}
                  </Stack>
                )}

                {!certificationSecondOpinion && (
                  <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                    <Text minW={{ base: 'auto', lg: '525px' }}>New Due Date</Text>
                    <CustomInput
                      id="newDueDate"
                      onChange={updateApplicationForm.handleChange}
                      value={updateApplicationForm.values.newDueDate}
                      maxW="202px"
                      type="date"
                    />
                  </Stack>
                )}
              </>
            )}
            {updateApplicationForm.values.fmlaApproved === 'no' && (
              <>
                {fmlaRejectionLoading ? (
                  <Spinner />
                ) : (
                  <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
                    <Text minW={{ base: 'auto', lg: '525px' }}>
                      Select Appropriate Reason
                    </Text>
                    {fmlaRejectionError ? (
                      <Text color="red" minW={{ base: 'auto', lg: '525px' }}>Failed To Fetch FMLA Rejection Options</Text>
                    ) : (
                      <CustomSelect
                        id="fmlaRejectedReason"
                        onChange={updateApplicationForm.handleChange}
                        value={updateApplicationForm.values.fmlaRejectedReason || ''}
                        minW={{ lg: '500px', xl: '800px' }}
                      >
                        {fmlaRejectionData.getOptions.map((v: KeyValueFields, k: number) => {
                          return <option
                            key={k}
                            value={v.value}
                            selected={v.value === application.fmlaRejectedReason}
                          >
                            {v.label || ''}
                          </option>
                        })}
                      </CustomSelect>
                    )}
                  </Stack>
                )}
              </>
            )}


          </VStack>
          <VStack spacing={5} align="flex-start">
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                Is the Leave a Block Leave or Intermittent Leave?
              </Text>

              <CustomSelect
                id="leaveType"
                onChange={updateApplicationForm.handleChange}
              >
                <option value={LeaveType.BLOCK} selected={application.leaveType === LeaveType.BLOCK}>Block Leave</option>
                <option value={LeaveType.INTERMITTENT} selected={application.leaveType === LeaveType.INTERMITTENT}>Intermittent Leave</option>
              </CustomSelect>
            </Stack>
            <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
              <Text minW={{ base: 'auto', lg: '525px' }}>
                When is the EE Expected to Return (Date)
              </Text>

              <CustomInput
                id="eeReturnDate"
                value={updateApplicationForm.values.eeReturnDate}
                onChange={updateApplicationForm.handleChange}
                maxW="202px"
                type="date"
              />
            </Stack>
          </VStack>
          <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
            <Text minW={{ base: 'auto', lg: '525px' }}>
              Date you learned about the FMLA or LOA
            </Text>

            <CustomInput
              id="designationFmlaLoaLearntDate"
              value={updateApplicationForm.values.designationFmlaLoaLearntDate}
              onChange={updateApplicationForm.handleChange}
              max={getCurrentDate()}
              maxW="202px"
              type="date"
            />
          </Stack>
          <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
            <Text minW={{ base: 'auto', lg: '525px' }}>
              Beginning Date of the LOA
            </Text>

            <CustomInput
              id="designationLoaBeginDate"
              value={updateApplicationForm.values.designationLoaBeginDate}
              onChange={updateApplicationForm.handleChange}
              maxW="202px"
              type="date"
            />
          </Stack>
          <Stack direction={{ base: 'column', lg: 'row' }} px="25px">
            <Text minW={{ base: 'auto', lg: '525px' }}>
              Weeks to Count Against FMLA
            </Text>
            {/*todo; duplicate backend calculation formula*/}
            <Text>{
              getWeeksToCountAgainstFMLA(updateApplicationForm.values.eeReturnDate,
                updateApplicationForm.values.designationLoaBeginDate,
                updateApplicationForm.values.designationFmlaLoaLearntDate)
            } Weeks
            </Text>
          </Stack>
        </VStack>
        <Box height={0} borderBottom="1px solid #03203E" />
        <Stack
          spacing={5}
          py="25px"
          justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
          alignItems={{ base: 'flex-end' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <PrimaryButton onClick={() => {
            updateApplicationForm.setFieldValue('status', ApplicationStatus.DESIGNATION_IN_PROGRESS)
            updateApplicationForm.submitForm().then(() => {
              navigate('/')
            })
          }}>Save and exit</PrimaryButton>
          <PrimaryButton
            onClick={() => {
              updateApplicationForm.setFieldValue('status', ApplicationStatus.DESIGNATION_IN_PROGRESS)
              updateApplicationForm.submitForm()
            }}
          >
            Save and continue
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              updateApplicationForm.setFieldValue('status', ApplicationStatus.COMPLETE)
              updateApplicationForm.submitForm().then(() => {
                navigate('/')
              })
            }}
          >
            Save & complete
          </SecondaryButton>
        </Stack>
      </form>

    </Flex>
  )
}

export default DesignationTable
