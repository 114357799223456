import {Button, ButtonProps} from '@chakra-ui/react'
import React from 'react'

const SecondaryButton: React.FC<ButtonProps> = props => {
  return (
    <Button
      maxH="47px"
      borderRadius="8px"
      background="#F5F5F5"
      minW="223px"
      color="#333333"
      px="15px"
      textTransform="uppercase"
      _hover={{
        background: '#F5F5F5',
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default SecondaryButton
