import { useFormik } from 'formik'
import { APPLICATION, APPLICATIONS, UPDATE_APPLICATION, UPDATE_APPLICATION_ELIGIBILITY_QUESTION } from '../services/aceFmlaGraphQL'
import { useMutation } from '@apollo/client'
import { ApplicationFields } from '../interfaces/generated/ApplicationFields'
import { ApplicationStatus } from '../interfaces/generated/globalTypes'
import { Applications } from '../interfaces/generated/Applications'
import { defaultListFilter } from '../modules/DashBoard/DashBoard'
import * as Yup from 'yup';

export type UseUpdateApplicationFormProps = {
  application: ApplicationFields,
}

 const UpdateApplicationSchema = Yup.object().shape({
   initialNoticeStartDate: Yup.string()
    .required('Required'),
    name: Yup.string()
     .required('Required'),
    initialNoticeFmlaLoaLearntDate: Yup.string()
     .required('Required'),
    intendedMailOutDate: Yup.string()
     .required('Required'),
    initialNoticeLoaBeginDate: Yup.string()
     .required('Required'),
    fmlaReason: Yup.string()
     .required('Required'),
 });

const useUpdateApplicationForm = ({ application }: UseUpdateApplicationFormProps) => {
  const [updateApplication] = useMutation(UPDATE_APPLICATION);
  const [updateApplicationEligibleQuestion] = useMutation(UPDATE_APPLICATION_ELIGIBILITY_QUESTION);
  const formik = useFormik({
    initialValues: {
      id: application.id,
      initialNoticeStartDate: application.initialNoticeStartDate,
      name: application.name,
      initialNoticeFmlaLoaLearntDate: application.initialNoticeFmlaLoaLearntDate,
      intendedMailOutDate: application.intendedMailOutDate,
      initialNoticeLoaBeginDate: application.initialNoticeLoaBeginDate,
      fmlaReason: application.fmlaReason,
      fmlaReasonDependent: application.fmlaReasonDependent,
      fmlaIneligibilityReason: application.fmlaIneligibilityReason,
      isKeyEmployee: application.isKeyEmployee,
      designationStartDate: application.designationStartDate,
      fmlaInitialReason: application.fmlaInitialReason,
      certificationReceivedDate: application.certificationReceivedDate,
      fmlaApproved: application.fmlaApproved,
      fmlaRejectedReason: application.fmlaRejectedReason,
      certificationIncompleteReason: application.certificationIncompleteReason,
      certificationInsufficientReason: application.certificationInsufficientReason,
      certificationSecondOrThirdOpinion: application.certificationSecondOrThirdOpinion,
      newDueDate: application.newDueDate,
      leaveType: application.leaveType,
      eeReturnDate: application.eeReturnDate,
      designationFmlaLoaLearntDate: application.designationFmlaLoaLearntDate,
      designationLoaBeginDate: application.designationLoaBeginDate,
      status: application.status,
      employeeWorkState: application.employeeWorkState,
      eligibleAnswers: application.eligibilityQuestionAnswers
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: UpdateApplicationSchema,
    onSubmit: async (input, { setErrors }) => {
      let normalizedFields =
        input.fmlaApproved === 'additional_information_needed' ? {
          certificationIncompleteReason: input.certificationInsufficientReason !== null || input.certificationSecondOrThirdOpinion !== null ? null : input.certificationIncompleteReason,
          certificationInsufficientReason: input.certificationIncompleteReason !== null || input.certificationSecondOrThirdOpinion !== null ? null : input.certificationInsufficientReason,
          certificationSecondOrThirdOpinion: input.certificationIncompleteReason !== null || input.certificationInsufficientReason !== null ? null : input.certificationSecondOrThirdOpinion,
          fmlaRejectedReason: null,
          fmlaReasonDependent: input.fmlaReasonDependent,
        } : {
          certificationIncompleteReason: null,
          certificationInsufficientReason: null,
          certificationSecondOrThirdOpinion: null,
          fmlaRejectedReason: input.fmlaApproved === 'no' ? input.fmlaRejectedReason : null,
          newDueDate: null,
          fmlaReasonDependent: input.fmlaReasonDependent,
        }
      const inputs = {
        applicationId: application.id,
        questions: input.eligibleAnswers?.map(answer => {
          return {
            questionId: answer.eligibilityQuestion.id,
            checked: answer.checked
          }
        })
      }

      await updateApplicationEligibleQuestion({
        variables: {
          input: inputs
        }
      }).catch(err => {
        alert('Error occurred')
      })
      if (input['eligibleAnswers']) {
        // @ts-ignore
        delete input["eligibleAnswers"]
      }
      await updateApplication({
        variables: {
          input: {
            ...input,
            ...normalizedFields
          }
        },
        update: (store, { data: { updateApplication: application } }) => {
          store.writeQuery({
            query: APPLICATION,
            data: {
              application
            }
          })
          // update list on new item
          const data: Applications | null = store.readQuery({
            query: APPLICATIONS,
            variables: defaultListFilter
          });
          if (data && !data.applications?.data.find((app) => app.id === application.id)) {
            store.writeQuery({
              query: APPLICATIONS,
              variables: defaultListFilter,
              data: {
                applications: {
                  data: [...(data?.applications ? data.applications.data : []), application]
                }
              }
            })
          }

        }
      })
        .then(() => {
          alert(`Application ${input.status === ApplicationStatus.COMPLETE ? 'Completed' : 'Updated'}`)
        })
        .catch((error: any) => {
          if (error) {
            console.log('error: ', error)
            alert('Error occurred')
          }
        })
    }
  })

  return { form: formik }
}
export default useUpdateApplicationForm
