import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import { getDocumentUrl } from '../../utils/document'
import { ApplicationFields } from '../../interfaces/generated/ApplicationFields'

export interface DocumentTableProps {
  application: ApplicationFields
}

export const tableContent = (applicationId: string) => ([
  {
    doc_name: 'General FMLA Poster',
    buttons: [{ text: 'Download', url: getDocumentUrl(applicationId, 'general-fmla-poster') }],
  },
  {
    doc_name: 'Notice of Eligibility & Rights and Responsibilities',
    buttons: [{ text: 'Download', url: getDocumentUrl(applicationId, 'noe-381') }],
  },
  {
    doc_name: 'HIPPA Release',
    buttons: [{ text: 'Download', url: getDocumentUrl(applicationId, 'hippa-release') }],
  },
  {
    doc_name: 'HC-EE & Family Member Only',
    buttons: [{ text: 'Download', url: getDocumentUrl(applicationId, 'hc-ee-and-family-member-only') }],
  },
  {
    doc_name: 'Designation Notice',
    buttons: [{ text: 'Download', url: getDocumentUrl(applicationId, 'designation-notice') }],
  },
])

const headers = ['Document Name']
const DocumentTable: React.FC<Partial<DocumentTableProps>> = ({ application }) => {
  console.log('application, ', application)
  const notEligible = application?.eligibilityQuestionAnswers?.find(eqa => !eqa.checked)
  return (
    <Table variant="simple">
      <Thead bg="primary" textTransform="uppercase">
        <Tr>
          {headers.map((header, i) => (
            <Th color="#FFF" fontSize="16px" key={i}>
              {header}
            </Th>
          ))}
          <Th />
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {tableContent(application?.id || '').filter((cnt) => {
          return !(application?.fmlaReason === 'birth_of_a_child' && cnt.doc_name === 'HC-EE & Family Member Only')
        }).map((cnt, i) => (
          <Tr key={i}>
            <Td>
              <b>{cnt.doc_name}</b>
            </Td>

            {cnt?.buttons?.map((btn, i) => (
              <Td isNumeric>
                <a href={btn.url} target='_blank' rel="noreferrer">
                  <PrimaryButton disabled={!!notEligible} textTransform="uppercase" key={i}>
                    {btn.text}
                  </PrimaryButton>
                </a>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
export default DocumentTable
