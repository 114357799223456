import {ButtonProps} from '@chakra-ui/react'
import React from 'react'
import {Container, Next, PageGroup, Paginator, Previous} from 'chakra-paginator'

export interface CustomPaginatorProps {
  total: number|undefined
  perPage: number|undefined
  page: number
  setPage: (arg0: number) => void
}


const normalStyles: ButtonProps = {
  w: 8,
  bg: "blue.100",
  fontSize: "sm",
  _hover: {
    bg: "blue.200"
  },
};

const activeStyles: ButtonProps = {
  w: 8,
  bg: "#03203E",
  fontSize: "sm",
  color: '#fff',
  _hover: {
    bg: "primary"
  },
};

const CustomPaginator: React.FC<CustomPaginatorProps> = ({page, setPage, total, perPage}) => {
  return (
    <Paginator
      activeStyles={activeStyles}
      normalStyles={normalStyles}
      pagesQuantity={(total && perPage) ? Math.ceil(total / perPage) : 1}
      currentPage={page}
      onPageChange={setPage}
    >
      <Container align="center" justify="space-between" w="full" p={4}>
        <Previous>
          Previous
        </Previous>
        <PageGroup isInline align="center" />
        <Next>
          Next
        </Next>
      </Container>
    </Paginator>
  )
}

export default CustomPaginator
