export const getCurrentDate = (): string => {
  const date = new Date()
  return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth()+1) + '-' + date.getDate()
}

export const getWeeksToCountAgainstFMLA = (
  designationLoaBeginDate: string|null,
  designationFmlaLoaLearntDate: string|null,
  eeReturnDate: string|null
): string => {
  if (!designationLoaBeginDate || !designationFmlaLoaLearntDate || !eeReturnDate) {
    return '-'
  }
  // calculate
  const loaBegin = (new Date(designationLoaBeginDate)).getTime() / 1000
  const loaLearnt = (new Date(designationFmlaLoaLearntDate)).getTime() / 1000
  const eeExpectedReturn = (new Date(eeReturnDate)).getTime() / 1000
  const diff = Math.abs(loaLearnt-loaBegin) / (3600*24)
  const dateToUse = diff <= 30 ? loaBegin : loaLearnt
  return Math.abs((eeExpectedReturn-dateToUse)/(3600*24*7)).toFixed(2).toString()
}