import {Button, ButtonProps} from '@chakra-ui/react'
import React from 'react'

const PrimaryButton: React.FC<ButtonProps> = props => {
  return (
    <Button
      maxH="47px"
      borderRadius="8px"
      background="primary"
      minW="223px"
      color="#FFF"
      px="15px"
      textTransform="uppercase"
      _hover={{
        background: 'primary',
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default PrimaryButton
