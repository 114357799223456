import {Global} from '@emotion/react'

const Fonts: React.FC = () => {
  return (
    <Global
      styles={`
   @font-face {
    font-family: 'Norwester';
    font-style: normal;
    font-weight: 400;
    src: local('Norwester'), url('./fonts/Norwester/norwester.woff') format('woff');
}
   @font-face {
    font-family: 'Norwester';
    font-style: normal;
    font-weight: 400;
    src: local('Norwester'), url('./fonts/Norwester/norwester.ttf') format('truetype');
}
   @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica Neue'), url('./fonts/HelveticaNeue/HelveticaNeue_Regular.woff') format('woff');
}
 @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica Neue'), url('./fonts/HelveticaNeue/HelveticaNeue_Regular.ttf') format('truetype');
}
 @font-face {
    font-family: 'Helvetica Neue Bold';
    font-style: normal;
    font-weight: bold;
    src: local('Helvetica Neue Bold'), url('./fonts/HelveticaNeue/HelveticaNeue_Bold.woff') format('woff');
}
 @font-face {
    font-family: 'Helvetica Neue Bold';
    font-style: normal;
    font-weight: bold;
    src: local('Helvetica Neue Bold'), url('./fonts/HelveticaNeue/HelveticaNeue_Bold.ttf') format('truetype');
}
 @font-face {
    font-family: 'Helvetica Neue Italic';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica Neue Italic'), url('./fonts/HelveticaNeue/HelveticaNeue_Italic.woff') format('woff');
}
 @font-face {
    font-family: 'Helvetica Neue Italic';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica Neue Italic'), url('./fonts/HelveticaNeue/HelveticaNeue_Italic.ttf') format('truetype');
}
    `}
    />
  )
}
export default Fonts
