/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationStatus {
  ALL = "ALL",
  COMPLETE = "COMPLETE",
  DESIGNATION_IN_PROGRESS = "DESIGNATION_IN_PROGRESS",
  INITIAL_NOTICE_COMPLETE = "INITIAL_NOTICE_COMPLETE",
  INITIAL_NOTICE_IN_PROGRESS = "INITIAL_NOTICE_IN_PROGRESS",
}

export enum FmlaDependentsClass {
  ALL = "ALL",
  DEP = "DEP",
  QE = "QE",
  SERVICE_M = "SERVICE_M",
}

export enum LeaveType {
  BLOCK = "BLOCK",
  INTERMITTENT = "INTERMITTENT",
}

export enum OptionType {
  DESIGNATION_REASONS = "DESIGNATION_REASONS",
  FMLA_APPROVAL = "FMLA_APPROVAL",
  FMLA_REASONS = "FMLA_REASONS",
  FMLA_REASON_DEPENDENTS = "FMLA_REASON_DEPENDENTS",
  FMLA_REJECTED_REASONS = "FMLA_REJECTED_REASONS",
  INELIGIBILIY_REASONS = "INELIGIBILIY_REASONS",
}

/**
 * Directions for ordering a list of records.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Allows ordering a list of records.
 */
export interface OrderByClause {
  column: string;
  order: SortOrder;
}

export interface QuestionAnswerInput {
  questionId: string;
  checked: boolean;
}

export interface UpdateApplicationEligibilityQuestionInput {
  applicationId: string;
  questions?: QuestionAnswerInput[] | null;
}

export interface UpdateApplicationInput {
  id: string;
  initialNoticeStartDate?: any | null;
  name?: string | null;
  initialNoticeFmlaLoaLearntDate?: any | null;
  intendedMailOutDate?: any | null;
  initialNoticeLoaBeginDate?: any | null;
  fmlaReason?: string | null;
  fmlaReasonDependent?: string | null;
  fmlaIneligibilityReason?: string | null;
  isKeyEmployee?: boolean | null;
  designationStartDate?: any | null;
  fmlaInitialReason?: string | null;
  certificationReceivedDate?: any | null;
  fmlaApproved?: string | null;
  fmlaRejectedReason?: string | null;
  certificationIncompleteReason?: string | null;
  certificationInsufficientReason?: string | null;
  certificationSecondOrThirdOpinion?: string | null;
  newDueDate?: any | null;
  leaveType?: LeaveType | null;
  eeReturnDate?: any | null;
  designationFmlaLoaLearntDate?: any | null;
  designationLoaBeginDate?: any | null;
  status: ApplicationStatus;
  employeeWorkState?: string | null;
}

export interface UpdateCompanyInput {
  addressLine1: string;
  addressLine2: string;
  phone: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
