import {Button, extendTheme} from '@chakra-ui/react'
import {createBreakpoints} from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '375px', // mobile
  md: '768px', // Tablet
  lg: '1024px', // Tablet
  xl: '1440px', // Desktop
  xxl: '1500px',
  xxxl: '2560px',
})
const fonts = {
  heading: 'Norwester',
  body: '"Helvetica Neue"',
}
const theme = extendTheme({
  components: {
    Button,
  },
  colors: {
    primary: '#78C7DE',
  },
  fonts,
  breakpoints,
})

export default theme
