import {Box, Flex, Heading, Spinner} from '@chakra-ui/react'
import React from 'react'
import BackLink from '../../components/BackLink/BackLink'
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import FMLAApplicationTable from '../../components/FMLAApplicationTable/FMLAApplicationTable'
import DesignationTable from '../../components/DesignationTable/DesignationTable'
import withPageContainer, {WithPageContainerProps} from '../../hoc/withPageContainer'
import { useParams } from "react-router-dom";
import {useQuery} from '@apollo/client'
import {APPLICATION} from '../../services/aceFmlaGraphQL'
import {ApplicationStatus} from '../../interfaces/generated/globalTypes'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'

const NewEditApplication: React.FC<WithPageContainerProps> = () => {
  const params = useParams();
  const { applicationId } = params;
  const { data, loading, error } = useQuery(APPLICATION, {
    variables: { id: applicationId }
  });
  return (
    <ContentWrapper py="50px" flexDirection="column" minH="60vh">
      <Flex justifyContent="space-between" py="25px">
        <Heading textTransform="uppercase" color="#03203E" fontSize="30px">
          Family Leave Application
        </Heading>
        <Box display={{base: 'none', lg: 'flex'}}>
          <BackLink url="/" text="back to dashboard" />
        </Box>
      </Flex>
      { loading ? (
        <Spinner />
      ) : error ? (
        <ErrorMessage>Error Fetching Application Data with id: {applicationId}</ErrorMessage>
      ) : (
        <>
          { data.application.status === ApplicationStatus.INITIAL_NOTICE_IN_PROGRESS && (
            <FMLAApplicationTable application={data.application}/>
          )}

          { (data.application.status === ApplicationStatus.DESIGNATION_IN_PROGRESS ||
            data.application.status === ApplicationStatus.INITIAL_NOTICE_COMPLETE) && (
            <DesignationTable application={data.application}/>
          )}
        </>
      )}
    </ContentWrapper>
  )
}

export default withPageContainer(NewEditApplication)
