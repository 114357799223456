import React from 'react'
import {HumanResourceFields} from '../interfaces/generated/HumanResourceFields'
import { useQuery } from '@apollo/client'
import {ME} from '../services/aceFmlaGraphQL'

export type WithPageContainerProps = {
  user?: HumanResourceFields | null
}

const withPageContainer = (PageComponent: any) => {
  return (props: any) => {
    let { user } = props

    if (!user) {
      const { data } = useQuery(ME);
      user = data?.me as HumanResourceFields
    }

    return (
      <>
        <PageComponent user={user} {...props} />
      </>
    )
  }
}

export default withPageContainer
